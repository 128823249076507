import * as React from "react";
import {
  H1,
  HBox,
  Icons,
  VBox,
  Button,
  Separator,
} from "@bakkt/components";
import {saveAs} from 'file-saver'
const win: any = window;
const SERVER_URL =
  win["SERVER_URL"] == "{{SERVER_URL}}"
    ? process.env.REACT_APP_SERVER_URL
    : win["SERVER_URL"];
export default class App extends React.Component <{},{downloading:boolean}>{
  constructor(props: any) {
    super(props);
    this.state={downloading:true}
  }
  async authenticate()
  {
   this.openLink(this.urlWithReportParameter('/web/download'))
    
  }

  async downloadFile()
  {
    const resp=await fetch(this.urlWithReportParameter('/report/consumer/download'), {
      method: 'GET',
      cache:'no-cache',
      credentials:'include'
    });
    if(resp.status==401)
    {
        this.authenticate()
    }
    else
    {
        const blob=await resp.blob();
        saveAs(blob,"CryptoTransactionReport.csv",{autoBom:true});
    }
   this.setState({downloading:false})
  }

  componentDidMount()
  {
    this.downloadFile();
  }


  openLink(url: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  urlWithReportParameter(path:string)
  {
    return `${SERVER_URL}${path}${document.location.search}`
  }

  logout() {
    this.openLink(`${SERVER_URL}/web/login-redirect?client=download`);
  }
  public render(): React.ReactNode {
    if(this.state.downloading){
      return <div></div>
    }
    return (
      <VBox
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 15,
        }}
      >
        <HBox
          style={{
            alignItems: "center",
            marginLeft: 100,
            marginRight: 100,
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <div style={{ display: "flex", flexGrow: 1 }}></div>
          <Icons.BakktLogo />
          <div style={{ display: "flex", flexGrow: 1 }}></div>
        </HBox>
        <div style={{flexGrow:1}}/>
        <div style={{ margin: "auto", flexGrow: 1 , flexDirection:'column'}}>
          <H1 style={{fontSize:32, margin:0}}>Crypto Transactions Report</H1>
          <p style={{marginTop:'0.5em'}}>Your download should begin automatically<br/>If not,&nbsp;
          <span style={{cursor: 'pointer',color:'blue'}}  onClick={e=>this.downloadFile()}>click here</span>.
          </p>
          
          <Separator />
          <p style={{marginTop:'0.5em'}}>When your download is complete, you can sign out.</p>
          <Button outline onClick={() => this.logout()}>
            Sign Out
          </Button>
        </div>
        <div style={{flexGrow:1}}/>
        
      </VBox>
    );
  }
}
